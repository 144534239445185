import { Theme } from "@material-ui/core/styles";
import bg from "../../images/bg_site.jpg";

export const marketing = (theme: Theme) => {
  return {
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "1440px 900px",
        backgroundPosition: "top center",
      },
    },
  };
};

export const form = (theme: Theme) => {
  return {
    container: {
      padding: `${theme.spacing(10)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
      marginLeft: "auto",
      marginRight: "auto",
    },
    title: {
      fontSize: "2.4rem",
      fontWeight: 300,
      textAlign: "center",
      marginBottom: theme.spacing(7),
    },
    gutter: {
      paddingRight: theme.spacing(7),
      borderRight: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: {
        border: "none",
        padding: 0,
      },
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      paddingLeft: theme.spacing(7),
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    authField: {
      margin: `${theme.spacing}px 0`,
    },
    progress: {
      margin: "0 auto",
    },
    togglePassword: {
      width: "32px",
      height: "32px",
      position: "absolute",
      right: 0,
      top: "12px",
      padding: 0,
    },
    orWrapper: {
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: `${theme.spacing(5)}px 0 ${theme.spacing(2)}px`,
      },
    },
    or: {
      position: "absolute",
      right: "-75px",
      top: "-165px",
      textAlign: "center",
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing,
      [theme.breakpoints.down("xs")]: {
        position: "static",
        margin: "0 auto -18px;",
        top: theme.spacing(4),
        width: theme.spacing(6),
      },
    },
    submit: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(4)}px`,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        boxShadow: "none",
      },
    },
    ico: {
      width: 50,
      position: "absolute",
      top: 20,
      left: 20,
    },
  };
};

export const dialog = (theme: Theme) => {
  return {
    checkbox: {
      paddingTop: 0,
    },
    dialogPaper: {
      minWidth: 500,
    },
  };
};
