import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { Switch } from "react-router";
import { Route, Router } from "react-router-dom";
import history from "../stores/History";
import NotFound from "./Errors/NotFound";
import HomePage from "./HomePage";

const Routes = () => {
  return (
    <Router history={history}>
      <div className="wrapper">
        <CssBaseline />
        <Switch>
          <Route exact={true} path="/" component={HomePage} />
          <Route path="/" component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;
