import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import firebase from "firebase";
import { SnackbarProvider } from "notistack";
import React from "react";
import Routes from "./Routes";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4a95fc",
      light: "#60b6ff",
      dark: "#1d3aab",
      contrastText: "#fff",
    },
    secondary: {
      main: "#000",
      light: "#b2b2b2",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "$labelColor",
          borderRadius: "4px",
        },
      },
    },
  },
});

const fbConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
};

firebase.initializeApp(fbConfig);

function App() {
  return (
    <SnackbarProvider
      preventDuplicate={true}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </SnackbarProvider>
  );
}

export const firebaseAuthContext = React.createContext(firebase.auth());
export default App;
