import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: any) =>
  createStyles({
    heroContent: {
      maxWidth: 1100,
      margin: "0 auto",
      padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
    },
  });

interface IProps extends WithStyles<typeof styles> {}

const NotFound = (props: IProps) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div>
          <div className={props.classes.heroContent}>
            <Typography variant="h5">Page Not Found</Typography>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default withStyles(styles)(NotFound);
