import { Divider, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";

import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router";
import logo from "../images/logo.svg";
import { marketing } from "./Styles/common";

const styles = (theme: any) =>
  createStyles({
    ...marketing(theme),
    heroContent: {
      margin: "0 auto",
      padding: `${theme.spacing(10)}px ${theme.spacing(4)}px ${theme.spacing(6)}px`,
      lineHeight: 1.5,
    },
    logo: {
      width: "240px",
      display: "block",
      margin: `0 auto ${theme.spacing(6)}px`,
    },
    heroButton: {
      padding: theme.spacing(3),
      width: 380,
      margin: `${theme.spacing(2)}px auto 0`,
      display: "block",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      textAlign: "center",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    buttonTeaser: {
      margin: `${theme.spacing(6)}px auto 0`,
    },
    title: {
      lineHeight: 1.2,
      letterSpacing: 1,
      marginBottom: theme.spacing(8),
      fontSize: "2.4rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
    titleBold: {
      color: theme.palette.primary.main,
    },
    heroSubtitle: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.light,
      width: 80,
      margin: "-14px auto 0",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        background: "none",
      },
    },
    divider: {
      width: 380,
      margin: "0 auto",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    substackContainer: {
      margin: "auto",
      width: "480px",
      height: "320px",
    },
    substack: {
      border: "0px",
      width: "100%",
      height: "100%",
      margin: "0",
      overflow: "hidden",
    },
  } as any);

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

const HomePage = observer((props: IProps) => {
  const { classes } = props;

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <div className={classes.heroContent}>
          <img alt="Nestor" src={logo} className={classes.logo} />
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom={true}
            className={classes.title}
          >
            Connecting <b className={classes.titleBold}>Financial Institutions</b>
            <br />
            to <b className={classes.titleBold}>Payments Innovation</b>
          </Typography>
          <Divider className={classes.divider} />
          <Typography component="h2" variant="body1" align="center" className={classes.heroSubtitle}>
            SOON
            <br />
            .&nbsp;.&nbsp;.
          </Typography>
          <div className={classes.substackContainer}>
            <iframe title="substack" src="https://neural.substack.com/embed" className={classes.substack} />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
});

export default withStyles(styles)(HomePage);
